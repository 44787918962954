import styled from 'styled-components'
import media from 'styled-media-query'
import Img from 'gatsby-image'
import { Whatsapp } from '@styled-icons/fa-brands'

export const WhatsappIcon = styled(Whatsapp)`
  position: relative;
  top: 2px;
  left: -1rem;
  color: var(--white);
`

export const CardContainer = styled.li`
  text-align: center;
  padding-bottom: 7rem;

  ${media.lessThan('small')`
    width: 100%;
  `}

  ${media.greaterThan('medium')`
    margin-right: 3.4rem;
  `}
`

export const CardButton = styled.a`
  display: inline-block;
  box-sizing: border-box;
  padding-top: 1.1rem;
  background: ${props => props.theme.colors.brand.primary[10]};
  color: var(--white);
  border: 0;
  border-radius: 4px;
  width: 28.6rem;
  height: 4.5rem;
  font-size: 14px;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
`

export const CardUrl = styled.a`
  display: block;
  color: #7D7D7D;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
`

export const CardImagePlaceholder = styled.div``

export const CardImage = styled(Img)`
  width: 28.8rem;
  height: 20rem;
  margin: 0 auto 1rem auto;
  background-color: var(--white);
  
  ${media.lessThan('small')`
    margin: 0 auto;
  `}
`
