import React from 'react'
import * as S from './styled'
import PropTypes from 'prop-types'

const RangeInput = props => {
  const handleOnChangeRangeInput = e => {
    props.setvalueRange(e.target.value)
  }

  const getPosition = () => {
    const min = 0
    const max = 300
    const percentage = (props.valueRange - min) / (max - min)
    const thumbWidth = 25
    const rangeWidth = 500 - thumbWidth
    return rangeWidth * percentage
  }

  return (
    <S.RangeWrapper>
      <S.RangeInputCustom
        max={300}
        min={0}
        value={props.valueRange}
        onChange={handleOnChangeRangeInput}
      />
      <S.RangeValue position={getPosition()}> {`${props.valueRange} Km`} </S.RangeValue>
    </S.RangeWrapper>
  )
}

RangeInput.propTypes = {
  valueRange: PropTypes.string,
  setvalueRange: PropTypes.func,
}

RangeInput.defaultProps = {}

export default RangeInput
