import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styled'

const BrandCard = ({ brand, acf }) => {
  const img = acf.imagem_da_unidade

  return (
    <S.CardContainer>
      <S.CardImagePlaceholder>
        <S.CardImage 
          fluid={img.localFile.childImageSharp.fluid}
          alt={brand}
        />
      </S.CardImagePlaceholder>
      
      {acf.link_botao && (
        <S.CardButton href={acf.link_botao} target="_blank">
          <S.WhatsappIcon size="20" />
          {acf.titulo_do_botao}
        </S.CardButton>
      )}

      {acf.url_do_site && (
        <S.CardUrl href={acf.url_do_site} target="_blank">
          {acf.titulo_da_url}
        </S.CardUrl>
      )}
    </S.CardContainer>
  )
}

BrandCard.propTypes = {
  brand: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    url: PropTypes.string,
    scheduling_url: PropTypes.string,
    logo: PropTypes.string,
  }),
}

export default BrandCard
