export const getEmojiFlagByCountry = (value) => {
    const links = () => {
        switch (value) {
            case 'BR':
                return ':flag-br:'
            case 'AR':
                return ':flag-ar:';
            default:
                return ':flag-br:'
        }
    }

    return links()
}
