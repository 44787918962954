import styled from 'styled-components'
import media from 'styled-media-query'

export const RangeWrapper = styled.div`
  position: relative;
  width: 500px;

  ${media.lessThan('medium')`
    width: 90%;
  `}
`

export const RangeInputCustom = styled.input.attrs({ type: 'range' })`
  width: 100%;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 12px;
  border-radius: 40px;
  background: ${props =>
    `linear-gradient(to right, #D22673 0%, #D22673 ${props.value / 3}%, #fff ${
      props.value / 3
    }%, #fff 100%);`};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 24px;
    height: 24px;
    background-image: radial-gradient(circle, #f7f7fc 40%, #02103f 45%);
    border-radius: 50%;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.5);
  }

  ::-moz-range-thumb {
    width: 24px;
    height: 24px;
    -moz-appearance: none;
    background-image: radial-gradient(circle, #f7f7fc 40%, #02103f 45%);
    border-radius: 50%;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.5);
  }
`

export const RangeValue = styled.span`
  position: absolute;
  top: -35px;
  left: ${({ position }) => `calc(${position}px + 12.5px)`};
  transform: translateX(-50%);
  background-color: #02103f;
  color: #fff;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
  pointer-events: none;
  white-space: nowrap;
`
