import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from 'components/layout'
import SEO from 'components/seo'
import ExamLocations from 'components/exam-locations'

const ExamLocationsPage = ({ data, location, pageContext }) => {
  const wordpressData = data.wordpressWpGatsbyPage.acf
  const examsLocationData = data.allWordpressWpExamLocationPage?.nodes
  return (
    <Layout location={location} pageContext={pageContext}>
      <SEO
        title={wordpressData.title}
        description={wordpressData.subtitle}
        canonical={wordpressData.canonical}
        pathName={location.pathname}
      />
      <ExamLocations 
        data={wordpressData}
        examsLocation={examsLocationData}
       />
    </Layout>
  )
}

ExamLocationsPage.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export default ExamLocationsPage

export const pageQuery = graphql`
  query($slug: String!) {
    wordpressWpGatsbyPage(slug: { eq: $slug }) {
      acf {
        title
        subtitle
        demonstrative_text
        canonical
      }
    }
    allWordpressWpExamLocationPage {
      nodes {
        id
        title
        acf {
          titulo_da_url
          url_do_site
          titulo_do_botao
          link_botao
          icone_botao
          imagem_da_unidade {
            alt_text
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
