import axios from 'axios'
import getUrlsByCountry from '../utils/getUrlsByCountry'
import { sendNotificationApi } from '../services/notificationService'

const routes = getUrlsByCountry(process.env.GATSBY_COUNTRY)

const getExamsApi = async (term, page, specialtyIds, ans, error, handleApiError) => {
  const termParam = term !== 'initial_param' ? term : false
  const ansParam = ans !== 'initial_param' ? ans : ''
  const specialtyParam = specialtyIds.join(',')
  const routeApiExams = routes.examsSearch
  const routeName = 'exams'
  const functionName = 'getExamsApi'

  const urlParams =
    `${termParam ? `?q=${term}` : '?q='}` +
    `${specialtyParam ? `&area=${specialtyIds}` : ''}` +
    `${ansParam !== '' ? `&ans=${ans}` : ''}` +
    `${`&page=${page}`}`

  return sendData(
    routeApiExams,
    urlParams,
    'GET',
    false,
    error,
    handleApiError,
    routeName,
    functionName
  )
}

const getSpecialtyApi = async (error, handleApiError) => {
  const urlParams = false
  const routeName = 'specialty'
  const functionName = 'getSpecialtyApi'

  return sendData(
    routes.specialty,
    urlParams,
    'GET',
    false,
    error,
    handleApiError,
    routeName,
    functionName
  )
}
const getUnitsApi = async (paramUf, paramCities, paramCep, error, handleApiError) => {
  const urlParams =
    `${paramUf ? `?uf=${paramUf}` : ''}` +
    `${
      paramCities && paramUf ? `&city=${paramCities}` : paramCities ? `?city=${paramCities}` : ''
    }` +
    `${
      paramCep !== '' && (paramUf || paramCities)
        ? `&cep=${paramCep}`
        : paramCep
        ? `?cep=${paramCep}`
        : ''
    }`
  const routeName = 'units'
  const functionName = 'getUnitsApi'

  return sendData(
    routes.units,
    urlParams,
    'GET',
    false,
    error,
    handleApiError,
    routeName,
    functionName
  )
}
const getCities = async (stateId, error, handleApiError) => {
  const urlParams = `?uf=${stateId}`
  const routeName = 'cities'
  const functionName = 'getCities'

  return sendData(
    routes.cities,
    urlParams,
    'GET',
    false,
    error,
    handleApiError,
    routeName,
    functionName
  )
}

const sendData = async (
  uri,
  urlParams,
  method,
  data = false,
  error,
  handleApiError,
  routeName,
  functionName
) => {
  if (typeof uri !== 'undefined' && !error.exams && !error.specialty) {
    const url = `${process.env.GATSBY_LARAVEL_API_URL}${uri}${urlParams ? urlParams : ''}`

    try {
      let request = {
        method,
        url,
      }

      if (data) {
        request = { ...request, data }
      }

      const fetchedResponse = await axios(request)
      handleApiError(routeName, false)

      return fetchedResponse.data
    } catch (error) {
      console.error('Erro na requisição:', error.message)
      handleApiError(routeName, true)

      sendNotificationApi(method, functionName, error.message, url)

      throw error
    }
  }
}

export { getExamsApi, getSpecialtyApi, getUnitsApi, getCities }
