import axios from 'axios';
import { getEmojiFlagByCountry } from '../utils/getEmojiFlagByCountry';

const iconItem = ':small_blue_diamond:'
const iconWarning = ':warning:'
const iconX = ':x:'

const sendNotificationSlack = async message => {
    const routeEndpoint = process.env.GATSBY_SLACK_NOTIFY_URL
    const urlRequest = `${process.env.GATSBY_LARAVEL_API_URL}${routeEndpoint}`
    const country = process.env.GATSBY_COUNTRY
    const flag = getEmojiFlagByCountry(country)
 
    let msg = ``
    msg += ` ${iconWarning} ---> ${flag} <--- ${iconWarning} \n`
    msg += `${message} \n`
    msg += `-----------------------------------------------------------------------`

    await axios({
        method: 'POST',
        url: urlRequest,
        data: { message: msg }
    })
}

export const sendNotificationApi = async (method, functionName, error, url) => {
    let message = ''
    message += `${iconItem} ${method} API Erro (${functionName}) \n`
    message += `${iconItem} Erro: ${error} ${iconX} \n`
    message += `${iconItem} URL: ${url}`

    await sendNotificationSlack(message)
}

export const sendNotificationNotFound = async (location) => {
    let message = ''
    message += `${iconItem} Página não encontrada (${location.pathname}) \n`
    message += `${iconItem} Erro: 404 - NOT FOUND ${iconX} \n`
    message += `${iconItem} URL: ${location.href}`

    await sendNotificationSlack(message)
}

export const sendNotificationErroBuild = async (stage) => {
    const linkJenkins = '<https://jenkins.internal.genera.com.br:8443/job/Genomica%20site/|Jenkins>'
    let message = ''
    message += `${iconItem} Build do site falhou! \n`
    message += `${iconItem} Stage: ${stage} ${iconX} \n`
    message += `${iconItem} Erro: Acesse o ${linkJenkins} para visualizar os logs de erros.  \n`

    await sendNotificationSlack(message)
}