import styled from 'styled-components'
import media from 'styled-media-query'

export const ContentModal = styled.div`
  position: fixed;
  top: 80px;
  width: 450px;
  left: calc(50% - 225px);
  border: 1px solid #333;
  border-radius: 5px;
  padding: 1rem 1rem 1rem 0;
  background-color: #fff;
  z-index: 99;

  ${media.lessThan('medium')`
    width: 350px;
    left: calc(50% - 175px);
  `}

  @media (max-width: 400px) {
    width: 96%;
    left: 0;
  }
`

export const ContainerIconClose = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  svg {
    cursor: pointer;
  }
`
