import React, { useEffect, useRef } from 'react'
import * as S from './styled'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

const ModalDasa = ({ showModal, setShowModal, children }) => {
  const ref = useRef(null)

  const handleSetShowModal = () => {
    setShowModal(false)
  }

  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        handleSetShowModal()
      }
    }
    if (showModal) {
      document.addEventListener('mousedown', handleClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal])

  if (!showModal) {
    return null
  }

  return (
    <>
      {showModal && (
        <S.ContentModal ref={ref}>
          <S.ContainerIconClose>
            <FontAwesomeIcon
              onClick={() => setShowModal(false)}
              color="#02103F"
              title="Fechar"
              size="lg"
              icon={faTimes}
            />
          </S.ContainerIconClose>
          {children}
        </S.ContentModal>
      )}
    </>
  )
}

ModalDasa.propTypes = {
  setShowModal: PropTypes.func,
  showModal: PropTypes.bool,
  children: PropTypes.node,
}

export default ModalDasa
